<template>
  <nuxt-link
    class="group block transition active:scale-[.97]"
    :to="{ name: 'categories-slug', params: { slug: tag.slug } }"
    @click="onClick"
  >
    <app-card
      class="flex h-full flex-col items-center gap-8 bg-white px-4 py-8 text-center shadow-primary transition-all hover:-translate-y-1 hover:shadow-lg"
    >
      <div
        class="grid aspect-square w-32 place-content-center bg-[--bgColor] transition-all clip-path-[--shape] group-hover:clip-path-[--hoverShape]"
        :style="{
          '--bgColor': bgColor,
          '--iconColor': iconColor,
          '--shape': shape,
          '--hoverShape': hoverShape,
        }"
      >
        <app-icon
          v-if="tag.icon"
          class="text-[--iconColor]"
          :icon="tag.icon"
          :size="50"
        />
      </div>

      <div class="flex-auto">
        <p class="text-xl font-bold">{{ tag.label }}</p>
        <p class="mt-1 text-sm text-subtle">
          {{ tag.total_number_of_courses }} formations disponibles
        </p>
      </div>

      <app-button color="dark" size="lg" variant="outlined"
        >Découvrir</app-button
      >
    </app-card>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { TagListOrigin } from "~/business-areas/analytics/analytics.model";
import * as BrandShapeRegistry from "~/business-areas/brand/brand-shapes";
import type { TagWithStatistics } from "~/business-areas/tag/tag.model";

const properties = defineProps<{
  origin: TagListOrigin;
  tag: TagWithStatistics;
}>();

const bgColor = computed(() =>
  hexToHSL(properties.tag.color, { luminance: 90 }),
);
const iconColor = computed(() =>
  hexToHSL(properties.tag.color, { luminance: 30 }),
);

const shape = computed(() => {
  const shapes = Object.values(BrandShapeRegistry);
  const firstSlugLetter = properties.tag.slug.charAt(0);

  const shapeIndex = Math.floor(
    ("abcdefghijklmnopqrstuvwxyz".indexOf(firstSlugLetter) * shapes.length) /
      26,
  );

  return shapes[shapeIndex];
});

const hoverShape = computed(() => {
  return pickRandomElementInArray(
    Object.values(BrandShapeRegistry).filter((item) => item !== shape.value),
  );
});

const { $analytics } = useNuxtApp();
const onClick = () =>
  $analytics.track("course_list_clicked", {
    origin: properties.origin,
    tags: [properties.tag.slug],
  });
</script>
