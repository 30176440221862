<template>
  <listbox
    v-slot="{ open }"
    v-model="selected"
    as="div"
    class="relative"
    :multiple="true"
  >
    <listbox-button
      as="div"
      class="flex cursor-pointer flex-wrap items-center gap-5"
      :class="$attrs.class"
      data-cy-tag-select
    >
      <slot name="selection" v-bind="{ selection: selected, open }">
        <p v-show="selected.length === 0" class="flex-auto">
          {{ $t("tag.select_form.placeholder") }}
        </p>

        <transition-group
          v-show="selected.length > 0"
          class="flex flex-auto flex-wrap items-center gap-2"
          enter-active-class="transition"
          enter-from-class="opacity-0 scale-95"
          leave-active-class="transition"
          leave-to-class="opacity-0 scale-95"
          tag="ul"
        >
          <li v-for="tag in selected" :key="tag.id">
            <tag-chip :data-cy-selected-tag-slug="tag.slug" :tag="tag" />
          </li>
        </transition-group>
      </slot>

      <slot name="append" v-bind="{ open }" />
    </listbox-button>

    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-2"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-2"
    >
      <listbox-options
        as="ul"
        class="absolute top-[calc(100%+0.5rem)] z-50 max-h-[270px] w-full min-w-[350px] overflow-auto rounded bg-white shadow-lg outline-none"
      >
        <li class="px-4 py-3 text-sm text-subtle">
          {{ $t("tag.select_form.items_title") }}
        </li>
        <listbox-option
          v-for="tag in data"
          :key="tag.id"
          v-slot="slot"
          as="template"
          :value="tag"
        >
          <li
            class="flex cursor-pointer items-center gap-4 px-4 py-3 hover:bg-primary-50"
            :class="{
              'font-semibold': slot.selected,
              'bg-primary-50': slot.active,
            }"
            :data-cy-tag-option-id="tag.id"
          >
            <app-icon v-if="tag.icon" :icon="tag.icon" :size="20" />
            {{ tag.label }}
            <div class="flex-auto text-right">
              <app-icon
                v-if="slot.selected"
                class="justify-self-end"
                icon="ph:check-circle"
              />
            </div>
          </li>
        </listbox-option>
      </listbox-options>
    </transition>
  </listbox>
</template>

<script lang="ts">
// eslint-disable-next-line import/no-default-export
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

import { useTagList } from "~/business-areas/tag/composables/tag-list.hook";
import type { TagWithStatistics } from "~/business-areas/tag/tag.model";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  modelValue?: DatabaseTable<"tags">["slug"][];
  placeholder?: I18nMessage;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", payload: DatabaseTable<"tags">["slug"][]): void;
}>();

const { data } = useTagList(
  { depth: 0, used: true },
  { server: false, lazy: true },
);

const selected = computed<TagWithStatistics[]>({
  get() {
    return (
      properties.modelValue
        ?.map((slug) => data.value?.find((tag) => tag.slug === slug))
        .filter(Boolean) ?? []
    );
  },
  set(value: TagWithStatistics[]) {
    emit(
      "update:modelValue",
      value.map((tag) => tag.slug),
    );
  },
});
</script>
