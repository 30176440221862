<template>
  <app-page-hero-banner
    class="@container"
    subtitle="Notre sélection d’experts vous partagent leurs méthodes et leur
          “secret sauce”"
    title="Prenez de l'avance en apprenant des meilleurs"
  >
    <template #cta>
      <div class="flex flex-wrap gap-4 @lg:flex-nowrap">
        <growth-contact-button
          class="basis-full @lg:basis-auto"
          origin="home"
          size="xl"
        >
          Demander une démo
        </growth-contact-button>

        <app-button
          class="basis-full @lg:basis-auto"
          color="light"
          size="xl"
          :to="{ name: 'catalog-index-top-trainers' }"
          variant="tonal"
          >Voir nos trainers</app-button
        >
      </div>
    </template>

    <template #append>
      <p class="mb-3 font-semibold">Thématiques populaires</p>

      <ul class="flex flex-wrap gap-2">
        <li v-for="link in links" :key="link.label">
          <app-chip
            class="bg-primary-950/50 transition hover:bg-white/5 active:scale-95"
            :to="link.to"
          >
            {{ link.label }}
          </app-chip>
        </li>
      </ul>
    </template>

    <template #aside>
      <nuxt-link
        class="grid h-full gap-3 [grid-template-areas:'side_top'_'side_bottom']"
        :to="{ name: 'catalog' }"
      >
        <brand-floating-label-slot
          v-for="(item, index) in list"
          :key="item.video_url"
          class="relative"
          :class="{
            '[grid-area:side]': index === 0,
            '[grid-area:top]': index === 1,
            '[grid-area:bottom]': index === 2,
          }"
          :color="
            item.teacher?.mainTag?.color
              ? hexToHSL(item.teacher.mainTag.color, {
                  luminance: 60,
                  saturation: 80,
                })
              : undefined
          "
        >
          <video
            v-if="item.video_url"
            autoplay
            class="absolute h-full w-full rounded-lg object-cover"
            disablePictureInPicture
            loop
            muted
            playsinline
            preload="metadata"
            :src="item.video_url"
          />

          <template v-if="item.teacher" #label>
            <p class="text-sm font-semibold">{{ item.teacher.full_name }}</p>
            <p class="text-xs">{{ item.teacher.job }}</p>
          </template>
        </brand-floating-label-slot>
      </nuxt-link>
    </template>
  </app-page-hero-banner>
</template>

<script lang="ts" setup>
import { type RouteLocationRaw } from "#vue-router";
import type { TagPageListView } from "~/business-areas/tag-page/composables/tag-page-list.hook";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import { useHomePageHeroVideoList } from "~/pages/index/_includes/composables/home-page-hero-video-list.hook";

const properties = defineProps<{
  tagPages: TagPageListView[];
}>();

const { list } = useHomePageHeroVideoList();

const links = computed<{ to: RouteLocationRaw; label: I18nMessage }[]>(() => {
  return [
    {
      label: "Top Trainers",
      to: { name: "catalog-index-top-trainers" },
    },
    {
      label: "Prochaines sessions",
      to: { name: "catalog-index-search" },
    },
    ...properties.tagPages.slice(0, 5).map((page) => {
      return {
        label: page.tag.label,
        to: page._links.page,
      };
    }),
  ];
});
</script>
