import type { TeacherListView } from "~/business-areas/teacher/teacher.model";

const DEFAULT_SESSION_VIDEO =
  "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1688637258/brand/home/enterprise_cover_online-video-cutter.com_ihvhf5.mp4";

const VIDEO_REGISTRY = {
  "benjamin-retourne":
    "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1728551621/brand/home/BENJAMIN_RETOURNE_VDEF_online-video-cutter.com_njbzrz.mp4",
  "claire-lebarz":
    "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686066133/brand/home/ClAIRE_LEBARZ_V1_online-video-cutter.com_pmf8ik.mp4",

  "jean-moreau":
    "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686043988/brand/home/s2hfsp6katrnfl0bcovt_online-video-cutter.com_1_mcxvkl.mp4",
  "jamie-wong":
    "https://res.cloudinary.com/dfdgwwkpd/video/upload/v1686065596/brand/home/JAMIE-WONG_online-video-cutter.com_1_oxixof.mp4",
} as const satisfies Record<string, string>;

type HighlightedTeacherSlug = keyof typeof VIDEO_REGISTRY;

export function useHomePageHeroVideoList() {
  const slugs = useState<HighlightedTeacherSlug[]>(
    "home_page_hero_teacher_selection",
    () => [],
  );

  callOnce(() => {
    slugs.value = shuffle(Object.entries(VIDEO_REGISTRY))
      .slice(0, 2)
      .map(([key]) => key as HighlightedTeacherSlug);
  });

  const { data: teachers } = useTeacherList(
    "home_page_hero",
    {
      slugs: slugs.value,
    },
    { server: false, lazy: true },
  );

  const list = computed<{ video_url: string; teacher?: TeacherListView }[]>(
    () => {
      return [
        ...slugs.value.map((slug) => {
          return {
            video_url: useCloudinaryVideo(VIDEO_REGISTRY[slug]).value!,
            teacher: teachers.value.find((teacher) => teacher.slug === slug),
          };
        }),
        {
          video_url: DEFAULT_SESSION_VIDEO,
        },
      ];
    },
  );

  return { list };
}
