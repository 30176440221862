<template>
  <div class="rounded-t-[40px] bg-gradient-to-b from-primary-50 from-30%">
    <app-page-wrapper
      class="grid min-h-[calc(100vh-var(--top-header-height))] !max-w-[1400px] items-center gap-20 py-[10vh] lg:grid lg:grid-cols-[50%_1fr] tall:min-h-0"
    >
      <div class="grid w-full max-w-[700px] gap-8 text-primary-950">
        <h1 class="font-title text-3xl lg:text-4xl xl:text-5xl/tight">
          Prenez de l'avance en apprenant des meilleurs
        </h1>

        <p class="balance text-xl">
          Notre sélection d’experts vous partagent leurs méthodes et leur
          “secret sauce”
        </p>

        <ul class="flex flex-wrap gap-x-8 gap-y-4 text-lg font-semibold">
          <li
            v-for="item in argumentItems"
            :key="item.icon"
            class="flex items-center gap-2"
          >
            <app-icon :icon="item.icon" />
            {{ item.label }}
          </li>
        </ul>

        <div>
          <tag-select-form
            class="w-full text-black"
            @submit="onSearchRequested"
          />

          <section v-if="pinnedCourses?.length" class="mt-6">
            <h2 class="mb-2 font-semibold">
              {{ $t("home_page.pinned_courses_title") }}
            </h2>

            <ul class="flex flex-wrap gap-2">
              <li v-for="course in pinnedCourses" :key="course.id">
                <pinned-course-chip
                  :course="course"
                  @click="onPinnedCourseClick(course)"
                />
              </li>
            </ul>
          </section>
        </div>
      </div>

      <client-only v-if="width >= 1024" class="hidden">
        <brand-people-grid
          v-motion-fade
          :cells="highlightedTeachers"
          :columns="2"
        />
      </client-only>
    </app-page-wrapper>
  </div>
</template>

<script lang="ts" setup>
import { toAnalyticsCourseView } from "~/business-areas/analytics/analytics.model";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import { useHomePageHighlightedTrainers } from "~/pages/index/_includes/old/composables/home-page-highlighted-teachers.hook";
import type { PinnedCourseView } from "~~/model/course.model";

const highlightedTeachers = useHomePageHighlightedTrainers();
const { width } = useWindowSize();

const argumentItems: { icon: string; label: I18nMessage }[] = [
  {
    label: "Formation 1 jour",
    icon: "ph:clock-clockwise",
  },
  {
    label: "En présence de l'expert",
    icon: "ph:shield-chevron",
  },
  {
    label: "Petit groupe",
    icon: "ph:users-three",
  },
];

const { data: pinnedCourses } = useFetch("/api/courses", {
  query: {
    pinned: true,
  },
});

const { $analytics } = useNuxtApp();

const onPinnedCourseClick = (course: PinnedCourseView) => {
  $analytics.track("course_clicked", {
    origin: "home_pinned_courses",
    course: toAnalyticsCourseView(course),
  });
};

function onSearchRequested(tags: DatabaseTable<"tags">["slug"][]) {
  $analytics.track("course_list_clicked", {
    origin: "home_hero",
    tags,
  });
}
</script>
