<template>
  <div
    class="grid gap-2"
    :class="{
      'grid-cols-[repeat(var(--columnCount),1fr)]': !!columns,
    }"
    :style="{
      '--columnCount': columns,
    }"
  >
    <div v-for="(cell, index) in cells" :key="cell.fullName">
      <brand-people-grid-cell
        class="transition-all duration-700"
        :definition="cell"
        :style="{
          clipPath: instanceShapes[index],
          transitionDelay: `${transitionDelays[index]}ms`,
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BrandPeopleGridCellDefinition } from "~/business-areas/brand/brand.model";
import * as availableShapes from "~/business-areas/brand/brand-shapes";

const getRandomShapeArray = (length: number) => {
  return shuffle(Object.values(availableShapes)).slice(0, length);
};

const SHAPE_ANIMATION_INTERVAL_IN_MS = 2500;

const properties = defineProps<{
  columns?: number;
  cells: BrandPeopleGridCellDefinition[];
}>();

const instanceShapes = ref(getRandomShapeArray(properties.cells.length));

const transitionDelays = ref(
  Array.from({ length: properties.cells.length }).map((_, index) => {
    return (index * 100) / properties.cells.length;
  }),
);

setInterval(() => {
  instanceShapes.value = getRandomShapeArray(properties.cells.length);
  transitionDelays.value = shuffle(transitionDelays.value);
}, SHAPE_ANIMATION_INTERVAL_IN_MS);
</script>
