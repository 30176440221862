<template>
  <div
    :class="{
      'bg-gradient-to-b from-primary-50 to-white to-[50vh] pt-4 sm:pt-8':
        homePageNewEnabled,
    }"
  >
    <template v-if="homePageNewEnabled">
      <home-page-hero :tag-pages="tagPages" />

      <app-page-wrapper class="mt-16 flex flex-col gap-16 md:mt-24 md:gap-24">
        <brand-customer-logos-banner />

        <app-page-subsection
          v-if="nextSessions.length > 0"
          title="Prochaines sessions"
          :to="{ name: 'catalog-index-search' }"
        >
          <course-card-carousel id="home_course_list" :list="nextSessions" />
        </app-page-subsection>
      </app-page-wrapper>

      <app-page-wrapper
        class="my-16 !max-w-[calc(theme(screens.3xl)+theme(spacing.24))] md:my-24"
      >
        <div
          class="rounded-lg bg-accent-50 p-6 text-accent-950 md:px-12 md:py-24"
        >
          <home-page-how-it-works />
        </div>
      </app-page-wrapper>

      <app-page-wrapper class="flex flex-col gap-16 md:gap-24">
        <app-page-subsection
          v-if="pinnedTeachers.length > 0"
          title="Top Trainers"
          :to="{ name: 'catalog-index-top-trainers' }"
        >
          <teacher-card-carousel :list="pinnedTeachers" />
        </app-page-subsection>

        <app-page-subsection
          v-if="tagPages.length > 0"
          title="Des experts dans chaque domaine"
          :to="{ name: 'catalog' }"
        >
          <app-carousel :item-gap="0" :item-width="250" :items="tagPages">
            <template #item="{ item }">
              <div class="h-full p-2">
                <tag-page-card class="h-full" origin="home_page" :page="item" />
              </div>
            </template>
          </app-carousel>
        </app-page-subsection>

        <brand-nps-banner />

        <press-list />

        <brand-qualiopi-banner />
      </app-page-wrapper>
    </template>

    <old-home-page v-else />
  </div>
</template>

<script lang="ts" setup>
import { startOfTomorrow } from "date-fns";

import HomePageHero from "~/pages/index/_includes/components/home-page-hero.vue";
import HomePageHowItWorks from "~/pages/index/_includes/components/home-page-how-it-works.vue";
import OldHomePage from "~/pages/index/_includes/old-home-page.vue";

const homePageNewEnabled = useFeatureFlag("home_page_new");

const { data: nextSessions } = useCourseCardList(
  "home",
  {
    from: startOfTomorrow().toISOString(),
    limit: 20,
  },
  { lazy: true, server: false },
);

const { data: pinnedTeachers } = useTeacherList(
  "pinned_teachers",
  {
    pinned: true,
  },
  {
    server: false,
    lazy: true,
  },
);

const { data: tagPages } = useTagPageList();
</script>
