<template>
  <app-page-section
    title="Relevez les défis de demain aux côtés des meilleurs d'aujourd'hui"
  >
    <ul class="grid gap-10 gap-y-16 md:grid-cols-3">
      <li v-for="item in reassuranceItems" :key="item.cover">
        <figure>
          <app-image alt="" class="rounded-lg" :src="item.cover" />
          <figcaption class="mt-8">
            <p class="mb-3 text-2xl font-extrabold">{{ item.title }}</p>
            <p>{{ item.subtitle }}</p>
          </figcaption>
        </figure>
      </li>
    </ul>

    <template #append>
      <growth-contact-button origin="home" size="xl" />
    </template>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";

const reassuranceItems: {
  title: I18nMessage;
  subtitle: I18nMessage;
  cover: string;
}[] = [
  {
    title: "Leurs méthodes ont fait leur preuve",
    subtitle:
      "Ils-elles sont en poste, et parlent d’expériences concrètes. Un processus de sélection et d’accompagnement ultra exigeant.",
    cover: "/images/brand/session/interior_3.jpg",
  },
  {
    title: "De vrais échanges en personne",
    subtitle:
      "Présentiel ou distanciel, en petits groupes de professionnels sélectionnés.",
    cover: "/images/brand/session/interior_4.jpg",
  },
  {
    title: "Le tout condensé en une journée",
    subtitle:
      "Nous savons que vous êtes très occupés. Nos sessions durent 1/2 ou 1 journée pour s'insérer dans votre emploi du temps.",
    cover: "/images/brand/session/interior_5.jpg",
  },
];
</script>
