<template>
  <div>
    <home-page-intro />

    <app-page-wrapper class="flex flex-col gap-44 pb-sections">
      <teacher-companies-logo-banner />

      <course-card-list-section
        id="home_course_list"
        :list="courses"
        title="Inscrivez-vous en avant première aux prochaines sessions"
      />
    </app-page-wrapper>

    <div class="bg-primary-950 py-32 text-primary-50">
      <app-page-wrapper>
        <home-page-reassurance />
      </app-page-wrapper>
    </div>

    <div class="bg-gradient-to-b from-accent-100 to-white">
      <app-page-wrapper class="flex flex-col gap-32 py-32">
        <app-page-section v-if="tags" title="Des experts dans chaque domaine">
          <app-carousel :item-gap="1" :item-width="280" :items="tags">
            <template #item="{ item }">
              <div class="h-full p-2">
                <tag-card class="h-full" origin="home_page" :tag="item" />
              </div>
            </template>
          </app-carousel>
        </app-page-section>

        <home-session-highlights />
      </app-page-wrapper>
    </div>

    <div class="bg-gradient-to-b from-deco-100 py-32 text-deco-950">
      <app-page-wrapper>
        <brand-nps-banner>
          <template #append>
            <search-results-button color="dark" origin="home_page" size="lg" />
          </template>
        </brand-nps-banner>
      </app-page-wrapper>
    </div>

    <app-page-wrapper>
      <press-list />
    </app-page-wrapper>
  </div>
</template>

<script lang="ts" setup>
import TagCard from "~/business-areas/tag/components/tag-card.vue";
import HomePageIntro from "~/pages/index/_includes/old/components/home-page-intro.vue";
import HomePageReassurance from "~/pages/index/_includes/old/components/home-page-reassurance.vue";
import HomeSessionHighlights from "~/pages/index/_includes/old/components/home-session-highlights.vue";

const { data: tags } = useTagList(
  {
    used: true,
    depth: 0,
    sort_field: "total_number_of_courses",
    sort_order: "DESC",
  },
  { server: false, lazy: true },
);

const { data: courses } = useCourseCardList(
  "home_page_old",
  {},
  { server: false, lazy: true },
);
</script>
