<template>
  <app-page-section v-if="data?.length" :title="$t('press.list_title')">
    <app-carousel :item-width="340" :items="data">
      <template #item="{ item }">
        <nuxt-link class="block h-full" :href="item.url" target="_blank">
          <app-card
            class="flex h-full flex-col justify-between gap-4 p-4 transition-shadow hover:shadow-lg"
            outlined
          >
            <figure>
              <div class="relative overflow-hidden rounded">
                <app-image
                  v-if="item.cover"
                  alt=""
                  class="w-full object-cover"
                  :height="220"
                  :src="item.cover"
                />

                <div
                  class="absolute bottom-0 grid h-1/2 w-full items-end bg-gradient-to-t from-black/80 p-4"
                >
                  <div class="flex items-center gap-2">
                    <app-image
                      v-if="item.website_logo"
                      alt=""
                      class="rounded bg-white object-cover p-1"
                      :height="30"
                      :src="item.website_logo"
                      :width="30"
                    />
                    <p class="text-sm font-semibold text-white">
                      {{ item.website_name }}
                    </p>
                  </div>
                </div>
              </div>

              <figcaption class="mt-4">
                <p class="mb-1 line-clamp-2 font-semibold">
                  {{ item.title }}
                </p>
              </figcaption>
            </figure>

            <app-button block color="dark" tag="span">{{
              $t("press.item_cta_label", { name: item.website_name })
            }}</app-button>
          </app-card>
        </nuxt-link>
      </template>
    </app-carousel>
  </app-page-section>
</template>

<script lang="ts" setup>
const { data } = useLazyFetch("/api/press", {
  server: false,
});
</script>
